<template>
	<div class="partial partial--artist">
		<button-gallery v-on:click.native="show = !show" v-bind:class="{ 'button-gallery--open': show }"></button-gallery>
		<content-band>
			<div slot="img-top" class="content-band__image content-band__image-short" :style="bioImage"></div>
			<h3 class="content-band__artist-profile">Artist Profile</h3>
			<h2 class="content-band__title">Kurtis Todd</h2>
			<p>Kurtis, a second generation artist, was inspired to pursue art as a career by his father Craig, who is well known in his native Florida for his oil paintings of local wildlife. A custom tattoo artist of 11 years, Kurtis enjoys blending many varieties of tattooing styles resulting in a unique quality to his work. He specializes in elaborate geometric designs with a sharp focus on dotwork and mandalas.</p>
			
		</content-band>

		<transition class="transition" name="fade" mode="out-in">
			<div class="artist-container" v-if="show">
				<artists></artists>
			</div>
		</transition>

		<image-grid :images="images"></image-grid>
	</div>
</template>

<script>
// Components
import ContentBand from '@/components/ContentBand'
import ImageGrid from '@/components/ImageGrid'
import ButtonGallery from '@/components/ButtonGallery'
import Artists from '@/components/Artists'

// Export
export default {
	name: 'artist',
	components: {
		ContentBand,
		ImageGrid,
		ButtonGallery,
		Artists,
	},
	data: () => ({
		bioImage: {
			backgroundImage: 'url(' + require('@/assets/images/artist-kurtistodd.jpg') + ')'
		},
		images: [
			require('@/assets/images/kurtis/blackandgreysleeve.jpg'),
			require('@/assets/images/kurtis/bluerosesleeve1.jpg'),
			require('@/assets/images/kurtis/bluerosesleeve2.jpg'),
			require('@/assets/images/kurtis/bluerosesleeve3.jpg'),
			require('@/assets/images/kurtis/cross.jpg'),
			require('@/assets/images/kurtis/cross1.jpg'),
			require('@/assets/images/kurtis/cross2.jpg'),
			require('@/assets/images/kurtis/crossgears.jpg'),
			require('@/assets/images/kurtis/darknightjoker.jpg'),
			require('@/assets/images/kurtis/dr.bob.jpg'),
			require('@/assets/images/kurtis/elephant.jpg'),
			require('@/assets/images/kurtis/flowerdesign.jpg'),
			require('@/assets/images/kurtis/ganesh.jpg'),
			require('@/assets/images/kurtis/gas.jpg'),
			require('@/assets/images/kurtis/girlseye.jpg'),
			require('@/assets/images/kurtis/heart2.jpg'),
			require('@/assets/images/kurtis/jesus.jpg'),
			require('@/assets/images/kurtis/letters.jpg'),
			require('@/assets/images/kurtis/letters2.jpg'),
			require('@/assets/images/kurtis/lioncloseup.jpg'),
			require('@/assets/images/kurtis/lionfresh.jpg'),
			require('@/assets/images/kurtis/mandala.jpg'),
			require('@/assets/images/kurtis/mandala1.jpg'),
			require('@/assets/images/kurtis/mandala2.jpg'),
			require('@/assets/images/kurtis/mandala3.jpg'),
			require('@/assets/images/kurtis/mandala4.jpg'),
			require('@/assets/images/kurtis/mandala5.jpg'),
			require('@/assets/images/kurtis/octopussleeve.jpg'),
			require('@/assets/images/kurtis/octopussleeve1.jpg'),
			require('@/assets/images/kurtis/octopussleeve4.jpg'),
			require('@/assets/images/kurtis/rosescar3.jpg'),
			require('@/assets/images/kurtis/seahorsedesign.jpg'),
			require('@/assets/images/kurtis/seanbackpiece2.jpg'),
			require('@/assets/images/kurtis/seanbackpiece3.jpg'),
			require('@/assets/images/kurtis/skullLotus2.jpg'),
			require('@/assets/images/kurtis/skullpoka.jpg'),
			require('@/assets/images/kurtis/sportsteamscutout.jpg'),
			require('@/assets/images/kurtis/turbo2.jpg'),
			require('@/assets/images/kurtis/watercolor.jpg'),
			require('@/assets/images/kurtis/watercolorlion.jpg'),
			require('@/assets/images/kurtis/wolfhead.jpg'),
		],
		show: false
	}),
	metaInfo() {
		return {
			title: 'Kurtis Todd',
			titleTemplate: '%s | Mike Parsons Ink',
		}
	},
}
</script>

<style lang="scss" scoped>

	.artist-container {
		position: fixed;
		top: 0;
		left:0;
		right:0;
		bottom:0;
		z-index: 32;
		overflow: auto;
		transform: translateY(0);
		transition: transform 400ms $easing-material, opacity 200ms $easing-material;

		&.fade-enter-active {
			//transition: opacity .5s;
			top: 100%;
			transform: translateY(100%);
			
		}

		&.fade-leave-active {
			opacity: 0;
			transform: translateY(100%);
		}
	}

	
</style>
