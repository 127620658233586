import { render, staticRenderFns } from "./KurtisTodd.vue?vue&type=template&id=711120cb&scoped=true&"
import script from "./KurtisTodd.vue?vue&type=script&lang=js&"
export * from "./KurtisTodd.vue?vue&type=script&lang=js&"
import style0 from "./KurtisTodd.vue?vue&type=style&index=0&id=711120cb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "711120cb",
  null
  
)

export default component.exports